<template>
  <b-overlay
    :show="$apollo.loading || mutationLoading"
  >
    <b-card class="shadow ">
      <div class="d-flex  align-items-center justify-content-between">
        <h3 class="">
          All Modules
        </h3>

        <b-form-group
          label="View as"
          label-for="btn-radios-1"
          label-size="sm"
        >
          <b-form-radio-group
            id="btn-radios-1"
            v-model="view"
            :options="[
              { text: 'Grid', value: 'grid' },
              { text: 'List', value: 'list' },
            ]"
            button-variant="light"
            buttons
            size="sm"
          />
        </b-form-group>
      </div>
      <hr>
      <!-- search input -->
      <div class="custom-search d-flex justify-content-start">
        <b-form-group
          class="form-inline"
          label="Search"
          label-size="sm"
        >
          <b-form-input
            v-model="searchTerm"
            class="d-inline-block mr-1"
            placeholder="Search Modules"
            type="text"
          />
        </b-form-group>
      </div>
      <!-- card -->
      <section
        v-if="view === 'grid'"
        class="grid-view mt-1"
      >
        <b-row v-if="modules.length===0">
          <b-col>
            <b-card
              tag="article"
              style="border-radius: 10px; margin-bottom: 0; height: 100%;"
              class="ecommerce-card"
            >
              <h6 class="d-flex align-items-center justify-content-center  mb-0 text-center">
                No modules are available
              </h6>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col
            v-for="(row, i) in modules"
            :key="i"
            :title="row.title"
            md="4"
          >
            <b-card
              :key="i"
              class="border border-secondary"
            >
              <template #header>
                <b-card-title class="d-flex align-items-center justify-content-center  mb-0 text-center">
                  {{ row.title }}
                </b-card-title>
                <div>
                  <b-badge
                    v-if="!row.is_default"
                    :variant="`${row.is_private ? 'primary' : 'secondary'}`"
                  >
                    <feather-icon :icon="`${row.is_private ? 'Lock' : 'Users'}Icon`" />
                    <span style="margin-left:4px">{{ row.is_private ? 'Private' : 'Public' }}</span>
                  </b-badge>
                  <b-badge
                    v-if="row.users_organizationtable && row.users_organizationtable.title"
                    class="ml-1"
                    variant="light-info"
                  >
                    <span class="ml-4">{{ row.users_organizationtable.title }}</span>
                  </b-badge>
                  <b-badge
                    v-else
                    variant="outline-primary"
                  >
                    Default
                  </b-badge>
                </div>
              </template>
              <b-card-text>{{ row.description }}</b-card-text>
              <b-card-footer class="p-0">
                <div class="d-flex justify-content-between mt-2">
                  <div>
                    <b-button
                      size="sm"
                      variant="primary"
                      class="mr-50"
                      :to="{ name: 'startup-library-details', params: { lid: row.id, mname: row.title } }"
                    >Open</b-button>
                  </div>
                </div>
              </b-card-footer>
            </b-card>
          </b-col>
        </b-row>
      </section>
      <!-- table -->
      <vue-good-table
        v-if="view === 'list'"
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        :rows="modules"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <span
            v-if="props.column.field === 'visibility'"
            class="d-flex flex-nowrap"
          >
            <b-badge
              v-if="!props.row.is_default"
              :variant="`${props.row.is_private ? 'primary' : 'secondary'}`"
            >
              <feather-icon :icon="`${props.row.is_private ? 'Lock' : 'Users'}Icon`" />
              <span style="margin-left:4px">{{ props.row.is_private ? 'Private' : 'Public' }}</span>
            </b-badge>

            <b-badge
              v-else
              variant="outline-primary"
            >
              Default
            </b-badge>
          </span>
          <span
            v-if="props.column.field === 'action'"
            class="d-flex flex-nowrap"
          >
            <b-button
              size="sm"
              variant="primary"
              class="mr-50"
              :to="{ name: 'startup-library-details', params: { lid: props.row.id, mname: props.row.title } }"
            >Open</b-button>
          </span>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['10','20','30']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :per-page="pageLength"
                :total-rows="props.total"
                :value="1"
                class="mt-1 mb-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </b-overlay>
</template>
<script>

import {
  BBadge,
  BButton,
  BCard,
  BCardFooter,
  BCardText,
  BCardTitle,
  BCol,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormSelect,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { required, size } from '@core/utils/validations/validations'

export default {
  components: {
    // BButtonGroup,
    BRow,
    BCard,
    BCol,
    // BTabs,
    BFormInput,
    BFormGroup,
    BCardTitle,
    BButton,
    VueGoodTable,
    BPagination,
    BCardFooter,
    // BLink,
    BCardText,
    BFormSelect,
    BBadge,
    BFormRadioGroup,
  },
  data() {
    return {
      view: 'list',
      mutationLoading: false,
      update: false,
      searchTerm: '',
      selectedRow: 0,
      pageLength: 10,
      filter: {
        status: 'Current',
      },
      rows: [],
      modules: [],
      operations: [],
      is_internal: true,
      is_private: true,
      file_link: null,
      file: null,
      recommendationLevel: null,
      title: null,
      description: '',
      moduletitle: null,
      moduledescription: '',
      selectedOperation: null,
      required,
      size,
    }
  },
  computed: {
    columns() {
      const baseColumns = [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Description',
          field: 'description',
        },
        {
          label: 'Visibility',
          field: 'visibility',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ]

      if (!this.hasSubdomain) {
        baseColumns.splice(1, 0, {
          label: 'Owner',
          field: 'users_organizationtable.title',
        })
      }

      return baseColumns
    },
    hasSubdomain() {
      return this.getIncubatorFromSubdomain() !== null
    },
  },
  methods: {
    updateView(view) {
      this.view = view
    },
  },
  apollo: {
    modules: {
      query() {
        return gql`
        query MyQuery($subdomain: String!) {
          knowledge_resource_module(where: {
            _or:[
              {
             is_private: {_eq: false},
            users_organizationtable:{
              domain: {_regex: $subdomain},
              usersAssociationtablesByAssociatedOrg:{
                 role:{_eq:"startup"},
                 }
                }
              },
               {
            users_organizationtable:{
              domain: {_regex: $subdomain},
              usersAssociationtablesByAssociatedOrg:{
                 role:{_in:["incubatee", "pre-incubatee","graduate"]},
                 }
                }
              }
            ]

          } ) {
            id
            incubator_id
            title
            is_private
            is_default
            description
            users_organizationtable {
              title
            }
          }
        }
        `
      },
      variables() {
        return {
          subdomain: this.getIncubatorFromSubdomain() || '.*',
        }
      },
      update: data => data.knowledge_resource_module,
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
